import { IonButton, IonCol, IonGrid, IonImg, IonRow, IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import { API_URL } from "../../actions/settings";
import "./AE2Home.css"
import Customer_Stock_IMG from "../../assets/Customers_Stock.png"
import ES_IMG from "../../assets/es.png"
import Alpha_Banner from "../../assets/alpha_banner.jpg"
import LoadingSpinner from "../extras/LoadingSpinner";
import GoogleCustomersMap from "../extras/GoogleCustomersMap";

const startTotals = {
  "total_sf": '',
  "total_buildings": '',
  "total_campus": '',
  "total_customers": '',
  "total_incentive":'',
  "total_bills":'',
  'total_estar_properties':'',
  'total_avoidance': 0
}

const startMetrics = {
	"month": "",
	"year": "",
	"max": {
		"cust_uuid": "",
		"cust_name": "",
		"building_uuid": "",
		"building_name": "",
		"score": ""
	},
	"avg": '',
	"min": {
		"cust_uuid": "",
		"cust_name": "",
		"building_uuid": "",
		"building_name": "",
		"score":""
	}
}

async function getTotals(token:any) {

  return fetch(`${API_URL}/totals`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }
async function getMonthlyMetrics(token:any) {

  return fetch(`${API_URL}/energystar/get_monthly_metrics`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json())
 }


 const handleTotals = async (token:any, setTotals:any, setMetrics:any,setTotalsLoaded:any, setMetricsLoaded:any) => {
  const totals = await getTotals(`Token ${token.slice(1,-1)}`)
  setTotals(totals)
  setTotalsLoaded(true)
  const metrics = await getMonthlyMetrics(`Token ${token.slice(1,-1)}`)
  setMetrics(metrics)
  setMetricsLoaded(true)
}

const AE2Home: React.FC = () => {

  const [totals,setTotals] = useState(startTotals)
  const [metrics,setMetrics] = useState(startMetrics)
  const [totalsLoaded,setTotalsLoaded] = useState(false)
  const [metricsLoaded,setMetricsLoaded] = useState(false)

  useEffect(()=>{
    const token = localStorage.getItem('token')
    handleTotals(token,setTotals,setMetrics,setTotalsLoaded,setMetricsLoaded)
  },[])

  const handleClick = (title:any) =>{
    if (window.innerWidth > 992){
    const block = document.getElementsByClassName(title)[0].firstChild as HTMLElement
    block!.click()
    }
    else{
      const menu = document.getElementsByClassName('buttons-last-slot')[0].firstChild as HTMLElement
      menu!.click()
      const block = document.getElementsByClassName(title)[0].firstChild as HTMLElement
      block!.click()
    }
  }

    return (

          <IonGrid >
              {/* <IonRow className="ion-justify-content-center">
                <div className="homepage-data">
                <p>This is the home page for the Alpha Enterprise Engine (AE2)</p>
                <p>Select a Customer, Campus, or Building from the menu to view it.</p>
                </div>
              </IonRow> */}
              <IonRow className="ion-justify-content-center homepage-top">
                <IonCol size="12" sizeMd="6">
                  <IonRow>
                    <IonImg className="ae2_banner" src={Alpha_Banner}/>
                  </IonRow>
                </IonCol>

              </IonRow>
              {/* <IonRow hidden={!(role==='Supervisor' || role==='Admin')} className="ion-justify-content-center"><IonButton color='secondary' href='/customer'>Create a Customer</IonButton></IonRow> */}

              <IonRow className="ion-justify-content-center">
                <GoogleCustomersMap/>
              </IonRow>

              <IonRow >
                <IonCol size="12" sizeMd="6" className="homepage-customers">
                    <IonRow className="ion-justify-content-center homepage-totals-row">
                       <IonCol className="ion-justify-content-center" size="12" sizeXl="8">
                      <IonRow><h2> Totals: </h2></IonRow>
                      {totalsLoaded ? <>
                      <IonRow><IonCol size="6">Total Square Footage:</IonCol><IonCol size="6">{Number(totals.total_sf).toLocaleString('en')} ft<sup>2</sup></IonCol></IonRow>
                      <IonRow><IonCol size="6">Total Incentive:</IonCol><IonCol size="6">${Number(totals.total_incentive).toLocaleString('en')}</IonCol></IonRow>
                      <IonRow><IonCol size="6">Total Cost Savings:</IonCol><IonCol size="6">${totals.total_avoidance.toLocaleString('en')}</IonCol></IonRow>
                      </> : <LoadingSpinner/>}
                      </IonCol>
                  </IonRow>
                  <IonRow className="homepage-customers">
                  <IonImg className="homepage-customer-img" src={Customer_Stock_IMG}/>
                  <IonText className="ae2-title">Customers</IonText>
                  <IonButton fill="outline" color='light' size="large" href="/customers" className="homepage-customer-btn">Customers ({Number(Number(totals.total_customers).toLocaleString('en'))})</IonButton>
                  <IonButton fill="outline" color='light' size="large" onClick={e=>handleClick('Campuses-block')} className="homepage-customer-btn">Campuses ({Number(totals.total_campus).toLocaleString('en')})</IonButton>
                  <IonButton fill="outline" color="light" size="large" onClick={e=>handleClick('Buildings-block')} className="homepage-customer-btn">Buildings ({Number(totals.total_buildings).toLocaleString('en')})</IonButton>
                  <IonButton fill="outline" color="light" size="large" href="/success_stories" className="homepage-customer-btn">Success Stories</IonButton>

                  </IonRow>
                </IonCol>
                <IonCol size="12" sizeMd="6" className="homepage-databases">
                {metricsLoaded ?  (metrics.month ? <IonRow className="ion-justify-content-center homepage-metrics-row">
                  <IonCol size="12" className="homepage-metrics">
                    <h2> EnergyStar Monthly Metrics For {metrics.month.trim()}, {metrics.year} </h2>
                      <IonRow >
                        <IonCol size="2" className="top-row border-2"></IonCol>
                        <IonCol size="2" className="top-row border-2 top">Score</IonCol>
                        <IonCol className="top-row top border-2">Customer</IonCol>
                        <IonCol className="top-row top border-2">Building</IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="left-row border-2" size="2">High</IonCol>
                        <IonCol size="2" className="data score border-2">{metrics.max.score}</IonCol>
                        <IonCol className="data border-2"><a href={`/?id=${metrics.max.cust_uuid}&sec=customers`}>{metrics.max.cust_name}</a></IonCol>
                        <IonCol className="data border-2"><a href={`/?id=${metrics.max.building_uuid}&sec=buildings`}>{metrics.max.building_name}</a></IonCol>

                      </IonRow>
                      <IonRow>
                        <IonCol className="left-row border-2" size="2">Average</IonCol>
                        <IonCol size="2" className="data score border-2" >{metrics.avg}</IonCol>
                        <IonCol className="data border-2"></IonCol>
                        <IonCol className="data border-2"></IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="left-row border-2" size="2">Low</IonCol>
                        <IonCol size="2" className="data score border-2">{metrics.min.score}</IonCol>
                        <IonCol className="data border-2"><a href={`/?id=${metrics.min.cust_uuid}&sec=customers`}>{metrics.min.cust_name}</a></IonCol>
                        <IonCol className="data border-2"><a href={`/?id=${metrics.min.building_uuid}&sec=buildings`}>{metrics.min.building_name}</a></IonCol>

                      </IonRow>
                  </IonCol>
                  </IonRow> : <div>No Metric Data Available</div>) : <LoadingSpinner/>}
                  <IonImg className="homepage-database-img" src={ES_IMG}/>
                  <IonText className="ae2-title">Databases</IonText>
                  <IonButton fill="outline" color='light' size="large" href="/energystar" className="homepage-customer-btn">Energy Star ({totals.total_estar_properties})</IonButton>
                  <IonButton fill="outline" color="light" size="large" href="/reapr" className="homepage-customer-btn">REAPR - Analytics</IonButton>
                  <IonButton fill="outline" color="light" size="large" href="/server_data" className="homepage-customer-btn">Server Data</IonButton>
                  <IonButton fill="outline" color="light" size="large" href="/reapr_status" className="homepage-customer-btn">REAPR System Status</IonButton>
                </IonCol>
              </IonRow>
          </IonGrid>


    );
  };

  export default AE2Home;
